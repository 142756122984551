<template>
  <div>
    <div class="figure-placeholder">
      <button class="remove-item-cta btn--times" @click="removeItemFromWishlist(product.product)">
        <Icon name="icon icon--times-xs" />
      </button>

      <nuxt-link :to="app.localePath(encodeURI(`/p/${product.product.sku}/${product.product.url_key}.html`))">
        <figure>
          <nuxt-img loading="lazy" :src="productGetters.getProductThumbnailImage(product.product)" alt=""/>
        </figure>
      </nuxt-link>
    </div>

    <div class="wishlist__list-item-info">
      <div>
        <nuxt-link :to="app.localePath(encodeURI(`/p/${product.product.sku}/${product.product.url_key}.html`))">
          <h3>
            {{ product.product.name }}
          </h3>
        </nuxt-link>
        <div class="label">For {{ product.product.listings === '763' ? "Sale" : "Rent" }}</div>
      </div>

      <button @click.prevent="postInterest(product)" class="btn cta btn--orange">
        <template v-if="!expressInterestLoading">
          Express Interest
        </template>
        <template v-else>
          <span class="loader loader--white"></span>
        </template>
      </button>

    </div>
  </div>
</template>
<script>
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import { useUser } from '~/modules/customer/composables/useUser';
import Icon from "~/almarwan/components/Icon.vue";
import { useUiState } from '~/composables';
import {useContext} from "@nuxtjs/composition-api";

export default {
  components: {
    Icon,
  },
  data() {
    return {
      expressInterestLoading: false,
    }
  },
  methods: {
    async productAlertNotifyInStockCTA(product) {
      this.expressInterestLoading = true;
      await this.productAlertNotifyInStock(product.id)
      this.expressInterestLoading = false;
    },
    postInterest(prod) {
      let prodCatObj = prod.product.categories.find(o => o.level == 2);
      let pArr =[
        {
          'id': prodCatObj.id,
          'name': prod.product.name,
          'sku': prod.product.sku,
          'type': this.rentOrBuy
        }
      ];
      this.setProdInfo(pArr);
      this.toggleMakeOfferModal();
      this.setExpressInterest();
    },
  },
  props: {
    product: {
      type: Object,
      required: true,
    }
  },
  setup() {
    const {
      removeItem
    } = useWishlist();
    const removeItemFromWishlist = async (product) => {
      await removeItem({ product });
    };
    const { app } = useContext();

    const { toggleMakeOfferModal, setExpressInterest, setProdInfo } = useUiState();
    const { productAlertNotifyInStock } = useUser();

    return {
      app,
      removeItemFromWishlist,
      productGetters,
      productAlertNotifyInStock,
      toggleMakeOfferModal,
      setExpressInterest,
      setProdInfo
    };
  }
}
</script>
<style lang="scss" scoped>
</style>
