








































































































import LazyHydrate from 'vue-lazy-hydration';
import {
  SfLoader,
  // SfProductCard,
  SfPagination,
  SfSelect,
} from '@storefront-ui/vue';
import {
  computed,
  defineComponent,
  onMounted,
  useRouter,
  useRoute,
  useContext,
} from '@nuxtjs/composition-api';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import type { Product } from '~/modules/catalog/product/types';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import wishlistGetters from '~/modules/wishlist/getters/wishlistGetters';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useUser } from '~/modules/customer/composables/useUser';
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';
import EmptyWishlist from '~/modules/wishlist/components/EmptyWishlist.vue';
import { ProductTypeEnum } from '~/modules/catalog/product/enums/ProductTypeEnum';
import WishlistCard from "~/modules/customer/pages/MyAccount/WishlistCard.vue"

import { useUiHelpers } from '~/composables';
import BreadCrumb from "~/almarwan/components/Breadcrumb.vue";
import Icon from "~/almarwan/components/Icon.vue";
import {useCustomerStore} from "~/modules/customer/stores/customer";

export default defineComponent({
  name: 'MyWishlist',
  components: {
    BreadCrumb,
    Icon,
    SfLoader,
    // SfProductCard,
    SfPagination,
    SfSelect,
    EmptyWishlist,
    LazyHydrate,
    WishlistCard,
  },
  methods: {
    toggleSidebar() {
      let sidebar = document.querySelector('.account__sidebar');

      if (sidebar.classList.contains('active')) {
        sidebar.classList.remove('active')
      } else {
        sidebar.classList.add('active')
      }
    }
  },
  setup() {
    const {
      load,
      loading,
    } = useWishlist();
    const route = useRoute();
    const {
      query: { page, itemsPerPage },
    } = route.value;
    const th = useUiHelpers();
    const { isRentOrBuy } = useCustomerStore();
    const {
      error: cartError,
      isInCart,
      loading: isCartLoading,
    } = useCart();
    const wishlistStore = useWishlistStore();

    const products = computed(() => wishlistGetters.getProducts(wishlistStore.wishlist));
    const pagination = computed(() => wishlistGetters.getPagination(wishlistStore.wishlist));

    // const { imageSizes } = useImage();

    const breadcrumbs = [{
      link: '/customer/my-wishlist',
      text: 'Account'
    }]

    const { isAuthenticated, productAlertNotifyInStock } = useUser();

    onMounted(async () => {
      await load({
        searchParams: {
          currentPage: page ? Number.parseInt(page.toString(), 10) : 1,
          pageSize: itemsPerPage ? Number.parseInt(itemsPerPage.toString(), 10) : 10,
        },
      });
    });
    return {
      isRentOrBuy,
      isInCart,
      isCartLoading,
      loading,
      pagination,
      productGetters,
      products,
      th,
      breadcrumbs,
      isAuthenticated,
      productAlertNotifyInStock,
    };
  }
});
